<template>
  <div class="home">
    <h1 style="padding:24px;">Send To Plating</h1>
  </div>
</template>
<script>

  export default {
    data() {
      return {
      
      }
    },
    mounted() {
    
    },
    setup() {
    
      return {  }
    },
  }
</script>
